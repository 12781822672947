var _ua = (function(u){
    return {
      Tablet:(u.indexOf("windows") != -1 && u.indexOf("touch") != -1 && u.indexOf("tablet pc") == -1)
        || u.indexOf("ipad") != -1
        || (u.indexOf("android") != -1 && u.indexOf("mobile") == -1)
        || (u.indexOf("firefox") != -1 && u.indexOf("tablet") != -1)
        || u.indexOf("kindle") != -1
        || u.indexOf("silk") != -1
        || u.indexOf("playbook") != -1,
      Mobile:(u.indexOf("windows") != -1 && u.indexOf("phone") != -1)
        || u.indexOf("iphone") != -1
        || u.indexOf("ipod") != -1
        || (u.indexOf("android") != -1 && u.indexOf("mobile") != -1)
        || (u.indexOf("firefox") != -1 && u.indexOf("mobile") != -1)
        || u.indexOf("blackberry") != -1
    }
})(window.navigator.userAgent.toLowerCase());

var hover = function() {
  $(window).on('load resize orientationchange', function() {
    if($(window).width() <= 750) {
      $('body').removeClass('pc_hover');
    } else {
      $('body').addClass('pc_hover');
    }
  });
}

/*
* ページ離脱アニメーション
* @param {Object} current 離脱するページ（前のページ）の情報
* @return Promise
*/
function leaveAnimation(current) {
const animation = anime.timeline()
.add({
  easing           : 'easeOutSine',
  targets          : current.container.querySelector('a[href]'),
  duration         : 400,
  opacity          : [1, 0]
})
.add({
  easing           : 'easeInOutCubic',
  targets          : current.container.querySelector('.container'),
  duration         : 1000,
  opacity          : [1, 0],
  translateX      : [0, -20]
}, '-=500');
return animation.finished;
}

function enterAnimation(next) {
const animation = anime.timeline()
.add({
  easing           : 'easeInSine',
  targets          : next.container.querySelector('a[href]'),
  duration         : 400,
  opacity          : [0, 1],
})
.add({
  easing           : 'easeInOutCubic',
  targets          : next.container.querySelector('.container'),
  duration         : 1000,
  opacity          : [0, 1],
  translateX      : [20, 0]
}, '-=500');
return animation.finished;
}

$(window).load(function () { //全ての読み込みが完了したら実行
  $('#loader-bg').delay(1000).fadeOut(800);
  $('#loader').delay(800).fadeOut(300);
  $('#wrap').css('display', 'block');

  $('.fixBox').each(function(i, box) {
		var maxHeight = 0;
		$(box).find('.fixItem').each(function() {
			if ($(this).height() > maxHeight) maxHeight = $(this).height();
		});
		$(box).find('.fixItem').height(maxHeight);
	});
});

function stopload(){
  $('#wrap').css('display','block');
  $('#loader-bg').delay(900).fadeOut(800);
  $('#loader').delay(600).fadeOut(300);
}

$(function() {

  $.fn.matchHeight._maintainScroll = true;
  $(".g1").matchHeight();
  $(".g2").matchHeight();



  var h = $(window).height();

  $('#wrap').css('display','none');
  $('#loader-bg ,#loader').height(h).css('display','block');

  setTimeout('stopload()',10000);

  var h = $(window).height();

  $('#wrap').css('display','none');
  $('#loader-bg ,#loader').height(h).css('display','block');

  setTimeout('stopload()',10000);

  hover();

  crossorigin="anonymous"
  src="https://polyfill.io/v3/polyfill.min.js?features=default%2CArray.prototype.find%2CIntersectionObserver"

  var colorArray = new Array("#69db19","#1ca9e8","#ed2488","#a922e8","#1437ed","#21c46c","#10DEBE");
  var color = colorArray[Math.floor( Math.random() * colorArray.length)];
  var box = document.getElementById('random')
  box.style.backgroundColor = color;

  const gaPush = pagename => {
  ga('send', 'pageview', pagename)
  }


  if(!_ua.Mobile&&!_ua.Tablet){
    luxy.init();

    barba.hooks.leave((data) => {
     luxy.cancel();
    });

    barba.hooks.after((data) => {
     luxy.init();
    });
  }
  barba.init({
    transitions: [{
      beforeEnter({ next }) {
        // replaceHeadTags(next)の処理
        gaPush(location.pathname)
        const scrollElem = document.scrollingElement || document.documentElement
        scrollElem.scrollTop = 0
      },
      async leave({ current, next, trigger }) {
        //ページを離れる時のアニメーション
        const leave = await leaveAnimation(current);
        return leave;
      },
      afterEnter({ current, next, trigger }) {
        //ページを表示する時のアニメーション
        enterAnimation(next);
      },
    }]
  });

  const eventDelete = e => {
    if (e.currentTarget.href === window.location.href) {
      e.preventDefault()
      e.stopPropagation()
      return
    }
  }

  const links = [...document.querySelectorAll('a[href]')]
  links.forEach(link => {
    link.addEventListener('click', e => {
      eventDelete(e)
    }, false)
  })

});
